import { ARCHER, CLERIC, DEVIL, HUMAN, MINOTAUR, POLAR_BEAR, TOAD, WIZARD } from "../units/card/UnitCards";
import UnitCard from "../units/card/UnitCard";
import { allSkills } from "../metadata/Skills";
import { reapplySkills } from "../units/card/MixCard";

export class Profile {
    constructor(data, userId) {
        this.data = data;
        this.userId = userId;
    }

    getDeck() {
        const result = [];
        for (let i = 0; i < this.data.deck.length && i < 8; i++) {
            const card = this.data.unitCards.find(card => card.id === this.data.deck[i]);
            if (card) {
                result.push(new UnitCard(card));
            }
        }
        return result;
    }

    getAllCards() {
        const result = [];
        for (const card of this.data.unitCards) {
            result.push(new UnitCard(card));
        }
        result.sort((a, b) => {
            const aDeckIndex = this.data.deck.indexOf(a.id);
            const bDeckIndex = this.data.deck.indexOf(b.id);
            if (aDeckIndex === -1 && bDeckIndex === -1) {
                return a.compare(b);
            } else if (aDeckIndex === -1) {
                return 1;
            } else if (bDeckIndex === -1) {
                return -1;
            }
            if (aDeckIndex !== bDeckIndex) {
                return aDeckIndex - bDeckIndex;
            }
            return a.compare(b);
        });
        return result;
    }

    giveCardAndPushIntoDeck(reward) {
        const card = this.drawCard(reward);
        this.data.unitCards.push(card);
        if (this.data.deck.length < 8) {
            this.data.deck.push(card.id);
        } else {
            this.data.deck[7] = card.id;
        }
        return card;
    }

    refreshCrown() {
        const timeElapsed = Date.now() - this.data.crown.timestamp;
        const additionalCrown = Math.floor(timeElapsed / (1000 * 60 * 6));
        if (this.data.crown.amount + additionalCrown > 1000) {
            if (this.data.crown.amount < 1000) {
                this.data.crown.amount = 1000;
            }
            this.data.crown.timestamp = Date.now();
        } else {
            this.data.crown.amount += additionalCrown;
            this.data.crown.timestamp += additionalCrown * 1000 * 60 * 6;
        }
    }

    drawCardWithCrown(reward) {
        this.refreshCrown();
        if (this.data.crown.amount > 0) {
            const card = this.drawCard(reward);
            this.data.unitCards.push(card);
            this.data.crown.amount -= 1;
            return card;
        }
        return null;
    }

    drawCard(reward) {
        const cardType = reward.type;
        const cardId = `${this.userId}-${Math.random().toString(36).substring(2, 15)}`;
        let card;
        if (cardType === 'human') {
            card = HUMAN.clone();
        } else if (cardType === 'archer') {
            card = ARCHER.clone();
        } else if (cardType === 'minotaur') {
            card = MINOTAUR.clone();
        } else if (cardType === 'cleric') {
            card = CLERIC.clone();
        } else if (cardType === 'polar-bear') {
            card = POLAR_BEAR.clone();
        } else if (cardType === 'wizard') {
            card = WIZARD.clone();
        } else if (cardType === 'toad') {
            card = TOAD.clone();
        } else if (cardType === 'devil') {
            card = DEVIL.clone();
        }
        card.id = cardId;
        card.ownerId = this.userId;
        card.ownerName = this.data.name;
        for (let skillLvl = 1; skillLvl <= reward.skillCap; skillLvl++) {
            let roll = Math.random();
            const must = skillLvl <= reward.skillMust;
            if (skillLvl === 1) {
                card.skills[skillLvl - 1] = [this.rollSkill(reward, skillLvl).type];
            } else if (skillLvl === 2) {
                if (reward.skillCap > 2) {
                    roll -= 0.3;
                }
                if (!must && roll > 0.3) {
                    break;
                } else if (!must && roll > 0.25) {
                    card.skills[skillLvl - 1] = [null];
                } else {
                    card.skills[skillLvl - 1] = [this.rollSkill(reward, skillLvl).type];
                }
            } else if (skillLvl === 3) {
                if (!must && roll > 0.25) {
                    break;
                } else if (!must && roll > 0.2) {
                    card.skills[skillLvl - 1] = [null];
                } else {
                    card.skills[skillLvl - 1] = [this.rollSkill(reward, skillLvl).type];
                }
            }
        }

        card = reapplySkills(card);
        return new UnitCard(card);
    }

    purchaseCard(card, listingInfo) {
        this.refreshCrown();
        if (this.data.crown.amount >= listingInfo.price) {
            this.data.crown.amount -= listingInfo.price;
            let newCardData = { ...card };
            if (newCardData.ownerId === this.userId) {
                newCardData.id = `${this.userId}-${Math.random().toString(36).substring(2, 15)}`;
            } else {
                newCardData.id = `${card.id}-${Math.random().toString(36).substring(2, 15)}`;
            }
            newCardData = reapplySkills(newCardData);
            this.data.unitCards.push(new UnitCard(newCardData));
        }
    }

    rollSkill(reward, skillLvl) {
        const skillPool = allSkills[skillLvl];
        const focusPool = skillPool.filter(skill => reward.skillFocus.includes(skill.type));

        const roll = skillLvl === 3 ? 0 : Math.random();
        if (roll < 0.5 && focusPool.length > 0) {
            return focusPool[Math.floor(Math.random() * focusPool.length)];
        } else {
            return skillPool[Math.floor(Math.random() * skillPool.length)];
        }
    }

    removeCard(cardId) {
        this.data.unitCards = this.data.unitCards.filter(card => card.id !== cardId);
        this.data.deck = this.data.deck.filter(id => id !== cardId);
    }

    replaceCard(card) {
        for (let i = 0; i < this.data.unitCards.length; i++) {
            if (this.data.unitCards[i].id === card.id) {
                this.data.unitCards[i] = card;
                break;
            }
        }
    }

    deleteCards(cards) {
        this.data.unitCards = this.data.unitCards.filter(card => !cards.includes(card.id));
        this.data.deck = this.data.deck.filter(id => !cards.includes(id));
    }

    shouldShowTutorial(scene) {
        this.data.tutorial ||= {};
        return !this.data.tutorial[scene];
    }

    setTutorialShown(scene) {
        this.data.tutorial ||= {};
        this.data.tutorial[scene] = true;
    }
}

window._profileMutex ||= Promise.resolve();

const SYNC = async (fn) => {
    const currentMutex = window._profileMutex;
    let mutexResolve;
    window._profileMutex = new Promise(resolve => mutexResolve = resolve);
    await currentMutex;
    try {
        return await fn();
    } finally {
        mutexResolve();
    }
}

export const fetchProfile = async () => {
    return await SYNC(async () => {
        try {
            const response = await fetch('/api/profile');
            if (response.ok) {
                const { data, user_id, updated_at } = await response.json();
                window['profileTimestamp'] = new Date(updated_at).getTime();
                return new Profile(data, user_id);
            }
            if (response.status === 401) {
                window.location.reload();
            } else {
                alert(`获取数据失败: ${await response.text()}`);
            }
        } catch (error) {
            alert(`获取数据失败: ${error.message}`);
        } 
    });
}

export const saveProfile = async (profile) => {
    return await SYNC(async () => {
        try {
            localStorage.setItem('profile', JSON.stringify(profile.data));
        } catch (error) {
            console.error(`本地保存数据失败: ${error.message}`);
        }
    
        try {
            const response = await fetch(`/api/saveProfile?timestamp=${window['profileTimestamp']}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(profile.data),
            });
            if (response.ok) {
                const { timestamp } = await response.json();
                window['profileTimestamp'] = new Date(timestamp).getTime();
                return;
            }
            if (response.status === 409) {
                await logout();
            } else if (response.status === 401) {
                window.location.reload();
            } else {
                alert(`保存数据失败: ${await response.text()}`);
            }
        } catch (error) {
            alert(`保存数据失败: ${error.message}`);
        }
    });

}

export const logout = async () => {
    try {
        const response = await fetch('/api/logout', {
            method: 'POST',
        });
        if (response.ok) {
            return;
        }
    } finally {
        window.location.reload();
    }
}
