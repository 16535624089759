import * as PIXI from 'pixi.js';
import assetLoader from './assets/AssetLoader';
import { createLoginForm } from './login/Login';
import { fetchProfile } from './game/profile/Profile';

class GameApp {
    async loadFonts() {
        await assetLoader.loadFonts();
    }

    loadInBackground() {
        assetLoader.loadInBackground();
    }

    async handleLogin() {
        const response = await fetch('/api/refreshToken', {
            method: 'POST',
        });
        if (response.ok) {
            return;
        }

        const overlay = document.createElement('div');
        overlay.style.position = 'absolute';
        overlay.style.top = 0;
        overlay.style.left = 0;
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        overlay.style.zIndex = 20;
        document.body.appendChild(overlay);
        const { form: loginForm, loginPromise } = createLoginForm();
        overlay.appendChild(loginForm);

        await loginPromise;
        overlay.remove();
    }

    async loadAssets() {
        this.modulePromise = import('./game/Game');

        this.loadProgress = document.createElement('div');
        this.loadProgress.style.position = 'absolute';
        this.loadProgress.style.top = 0;
        this.loadProgress.style.left = 0;
        this.loadProgress.style.width = '100%';
        this.loadProgress.style.height = '100%';
        this.loadProgress.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        this.loadProgress.style.color = 'white';
        this.loadProgress.style.fontSize = '48px';
        this.loadProgress.style.display = 'flex';
        this.loadProgress.style.flexDirection = 'column';
        this.loadProgress.style.justifyContent = 'center';
        this.loadProgress.style.alignItems = 'center';
        this.loadProgress.style.fontFamily = 'Fusion Pixel';
        this.loadProgress.style.zIndex = 10;
        const loadingGif = document.createElement('img');
        loadingGif.src = '/web_images/loading.gif';
        loadingGif.style.width = '200px';
        loadingGif.style.height = '200px';
        this.loadProgress.appendChild(loadingGif);
        const loadingInfoText = document.createElement('div');
        loadingInfoText.style.fontFamily = 'Fusion Pixel';
        loadingInfoText.style.fontSize = '24px';
        loadingInfoText.style.color = 'white';
        loadingInfoText.innerHTML = '阿梓从小就很可爱 生日快乐！';
        this.loadProgress.appendChild(loadingInfoText);
        const loadProgressText = document.createElement('div');
        this.loadProgress.appendChild(loadProgressText);
        document.body.appendChild(this.loadProgress);

        await new Promise(resolve => setTimeout(resolve, 10));
        await assetLoader.load((progress) => {
            loadProgressText.innerText = `${Math.floor(progress * 90)}%`;
        });
    }

    async initApp() {
        this.app = new PIXI.Application();
        const fullWidth = window.visualViewport.width || window.innerWidth;
        const fullHeight = window.visualViewport.height || window.innerHeight;
        window['renderingPixelRatio'] = window.devicePixelRatio;
        if (window['renderingPixelRatio'] > 3) {
            window['renderingPixelRatio'] = 3;
        }
        await this.app.init({
            width: fullWidth * window['renderingPixelRatio'],
            height: fullHeight * window['renderingPixelRatio'],
            backgroundColor: 0x6FA430,
            resolution: 1,
            eventMode: 'passive',
            eventFeatures: {
                move: true,
                globalMove: false,
                click: true,
                wheel: false,
            }
        });
        this.app.canvas.style.width = `${fullWidth}px`;
        this.app.canvas.style.height = `${fullHeight}px`;
        document.body.appendChild(this.app.canvas);
        this.app.canvas.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });

        this.module = await this.modulePromise;
        const profile = await fetchProfile();
        this.loadProgress.remove();

        this.width = Math.max(Math.min(fullWidth * window['renderingPixelRatio'], 960), 240);
        this.height = Math.max(Math.min(fullHeight * window['renderingPixelRatio'], 1920), 480);
        if (this.width > this.height / 2) {
            this.height = this.height - this.height % 64;
            this.width = this.height / 2;
        } else {
            this.width = this.width - this.width % 32;
            this.height = this.width * 2;
        }

        const Game = this.module.default;
        this.game = new Game(this.app, this.width, this.height, profile);
    }

    startGame() {
        this.app.ticker.maxFPS = 30;
        this.app.ticker.add((ticker) => {
            this.game.update(ticker.deltaTime);
        });
        this.app.stage.addChild(this.game.display);
    }
}

// Start the game when the page loads
window.onload = async () => {
    PIXI.TextureStyle.defaultOptions.scaleMode = 'nearest';
    const gameApp = new GameApp();
    await gameApp.loadFonts();
    gameApp.loadInBackground();
    await gameApp.handleLogin();
    await new Promise(resolve => setTimeout(resolve, 10));
    await gameApp.loadAssets();
    await gameApp.initApp();
    gameApp.startGame();
};
