import UnitCard from "./UnitCard";
import { allSkillNames, allSkills, skillColor, skillFocus } from "../../metadata/Skills";
import { HUMAN, ARCHER, MINOTAUR, CLERIC, POLAR_BEAR, WIZARD, TOAD, DEVIL, BOOK, CACTUS } from "./UnitCards";

export const mixCard = (leftCard, rightCard) => {
    let resultCard = new UnitCard({...leftCard});
    const resultList = [];

    const leftSlotCount = countSkillSlot(leftCard);
    const rightSlotCount = countSkillSlot(rightCard);
    if (rightSlotCount >= leftSlotCount) {
        extendSkillSlot(resultCard);
        resultList.push({text: '技能槽增加', color: 0x000000});
    } else {
        const diff = leftSlotCount - rightSlotCount;
        const rate = 1 / Math.pow(2, diff);
        const roll = Math.random();
        if (roll < rate) {
            extendSkillSlot(resultCard);
            resultList.push({text: '技能槽增加', color: 0x000000});
        }
    }

    const addRate = rightCard.type === leftCard.type ? [1/2, 1/2, 1/3] : [1/2, 1/3, 1/4];
    const replaceRate = rightCard.type === leftCard.type ? [1, 1, 2/3] : [1, 2/3, 2/4];

    const skillsToAdd = [];
    for (let i = 0; i < 3; i++) {
        const resultSkillList = resultCard.skills[i];
        const candidateSkillList = [...rightCard.skills[i]];

        // random shuffle candidateSkillList
        while (candidateSkillList.length < resultSkillList.length) {
            candidateSkillList.push(null);
        }
        for (let k = candidateSkillList.length - 1; k > 0; k--) {
            const j = Math.floor(Math.random() * (k + 1));
            [candidateSkillList[k], candidateSkillList[j]] = 
                [candidateSkillList[j], candidateSkillList[k]];
        }

        for (let j = 0; j < candidateSkillList.length; j++) {
            const skill = candidateSkillList[j];
            if (skill === null) {
                continue;
            }

            const roll = Math.random();
            if (roll < addRate[i]) {
                skillsToAdd.push(skill);
            } else if (roll < replaceRate[i]) {
                if (j < resultSkillList.length && !resultSkillList.includes(skill)) {
                    if (resultSkillList[j]) {
                        resultList.push({text: `-技能：${allSkillNames[resultSkillList[j]]}`, color: 0xb31d1d});
                    }
                    resultList.push({text: `+技能：${allSkillNames[skill]}`, color: skillColor[i]});
                    resultSkillList[j] = skill;
                }
            }
        }

        let addedCount = 0;
        for (const skill of skillsToAdd) {
            if (resultSkillList.includes(skill)) {
                continue;
            }
            for (let k = 0; k < resultSkillList.length; k++) {
                if (resultSkillList[k] === null) {
                    resultList.push({text: `+技能：${allSkillNames[skill]}`, color: skillColor[i]});
                    resultSkillList[k] = skill;
                    addedCount++;
                    break;
                }
            }
        }

        const leftCount = skillsToAdd.length - addedCount;
        skillsToAdd.length = 0;
        if (i < 2 && Math.random() < leftCount / 2) {
            skillsToAdd.push(getRandomSkill(i + 2, resultCard.type).type);
        }
    }

    resultCard = reapplySkills(resultCard);

    return {
        card: resultCard,
        list: resultList
    };
}

export const reapplySkills = (originalCard) => {
    const cardType = originalCard.type;
    if (cardType.startsWith('spell-')) {
        return originalCard;
    }
    let card;
    if (cardType === 'human') {
        card = HUMAN.clone();
    } else if (cardType === 'archer') {
        card = ARCHER.clone();
    } else if (cardType === 'minotaur') {
        card = MINOTAUR.clone();
    } else if (cardType === 'cleric') {
        card = CLERIC.clone();
    } else if (cardType === 'polar-bear') {
        card = POLAR_BEAR.clone();
    } else if (cardType === 'wizard') {
        card = WIZARD.clone();
    } else if (cardType === 'toad') {
        card = TOAD.clone();
    } else if (cardType === 'devil') {
        card = DEVIL.clone();
    } else if (cardType === 'book') {
        card = BOOK.clone();
    } else if (cardType === 'cactus') {
        card = CACTUS.clone();
    }
    card.id = originalCard.id;
    card.ownerId = originalCard.ownerId;
    card.ownerName = originalCard.ownerName;
    card.skills = [];
    for (let i = 0; i < originalCard.skills.length; i++) {
        card.skills.push([...originalCard.skills[i]]);
    }

    card.level = 0;
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < card.skills[i].length; j++) {
            if (card.skills[i][j] !== null) {
                card.level = i;
                break;
            }
        }
    }

    for (let i = 1; i < 3; i++) {
        if ((card.type === 'toad' || card.type === 'devil') && i === 1) {
            continue;
        }
        for (let j = 0; j < card.skills[i].length; j++) {
            if (card.skills[i][j] === null) {
                continue;
            } else {
                card.cost += 1;
                break;
            }
        }
    }
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < card.skills[i].length; j++) {
            if (card.skills[i][j] === null) {
                continue;
            }
            const skill = card.skills[i][j];
            if (skill === 'hp') {
                card.hp += i + 1;
            } else if (skill === 'atk') {
                card.atk += i + 1;
            } else if (skill === 'def') {
                card.def += i + 1;
            } else if (skill === 'spd') {
                card.spd += i + 1;
            } else if (skill === 'atk-spd') {
                card.atkSpd += i + 1;
            } else if (skill === 'atk-range') {
                card.atkRange += i + 1;
            } else if (skill === 'cost') {
                if (i === 0) {
                    card.cost = Math.max(1, card.cost - 1);
                } else {
                    card.cost = Math.max(1, card.cost - (i + 2));
                }
            } else if (skill === 'stronger') {
                card.hp += 1;
                card.atk += 1;
                card.def += 1;
                card.spd += 1;
                card.atkRange += 1;
            } else if (skill === 'aoe') {
                if (card.atkType === 'melee') {
                    card.atkType = 'melee-area';
                    card.atkRange += 1;
                } else if (card.atkType === 'arrow') {
                    card.atkType = 'arrow-multiple';
                    card.aoeRadius = 15;
                } else if (card.atkType === 'arrow-multiple') {
                    card.aoeRadius += 10;
                } else if (card.atkType === 'magic') {
                    card.aoeRadius += 10;
                } else if (card.atkType === 'melee-area') {
                    card.atkRange += 1;
                } else if (card.atkType === 'heal') {
                    card.atkType = 'heal-area';
                    card.atkRange -= 1;
                } else if (card.atkType === 'heal-area') {
                    card.atkRange += 1;
                }
            }
        }
    }
    return card;
}

export const getMixCost = (leftCard, rightCard) => {
    let cost = 0;

    let leftSlotCount = countSkillSlot(leftCard);
    let rightSlotCount = countSkillSlot(rightCard);
    cost += Math.pow(2, Math.min(leftSlotCount, rightSlotCount));

    for (let i = 0; i < rightCard.skills.length; i++) {
        const base = Math.pow(2, i+1);
        let count = 0;
        for (let j = 0; j < rightCard.skills[i].length; j++) {
            if (rightCard.skills[i][j] !== null) {
                count++;
            }
        }
        if (count > 0) {
            cost += Math.pow(base, count);
        }
    }

    return cost;
}

export const getCardValue = (card) => {
    let value = 0;
    let slotCount = countSkillSlot(card);
    value += Math.pow(2, slotCount);

    for (let i = 0; i < card.skills.length; i++) {
        const base = Math.pow(2, i+1);
        let count = 0;
        for (let j = 0; j < card.skills[i].length; j++) {
            if (card.skills[i][j] !== null) {
                count++;
            }
        }
        if (count > 0) {
            value += Math.pow(base, count);
        }
    }
    return value;
}

const extendSkillSlot = (card) => {
    const slot = getSlotToExtend(card);
    if (card.skills[slot].length < 3) {
        card.skills[slot].push(null);
    }
}

const getSlotToExtend = (card) => {
    for (let i = 1; i < 3; i++) {
        if (card.skills[i].length < card.skills[i - 1].length) {
            return i;
        }
    }
    return 0;
}

const countSkillSlot = (card) => {
    let count = 0;
    for (let i = 0; i < card.skills.length; i++) {
        count += card.skills[i].length;
    }
    return count;
}

const countSkill = (card) => {
    let count = 0;
    for (let i = 0; i < card.skills.length; i++) {
        for (let j = 0; j < card.skills[i].length; j++) {
            if (card.skills[i][j] !== null) {
                count++;
            }
        }
    }
    return count;
}

const getRandomSkill = (lvl, type) => {
    const skillPool = allSkills[lvl];
    const skillFocusPool = skillFocus[type];
    const focusPool = skillPool.filter(skill => skillFocusPool.includes(skill.type));

    if (focusPool.length > 0) {
        return focusPool[Math.floor(Math.random() * focusPool.length)];
    }
    return skillPool[Math.floor(Math.random() * skillPool.length)];
}
