import { Assets, Texture, Rectangle } from "pixi.js"

const manifest = {
    "bundles": [
        {
            "name": "terrain",
            "assets": [
                { "alias": "tiles", "src": "./assets/terrain/Tiles.png" },
                { "alias": "props", "src": "./assets/terrain/Props.png" },
                { "alias": "props-shadow", "src": "./assets/terrain/PropsShadow.png" }
            ]
        },
        {
            "name": "units",
            "assets": [
                {
                    "alias": "amazon-attack",
                    "src": "./assets/units/human/AmazonAttack.png"
                },
                {
                    "alias": "amazon-die",
                    "src": "./assets/units/human/AmazonDie.png"
                },
                {
                    "alias": "amazon-idle",
                    "src": "./assets/units/human/AmazonIdle.png"
                },
                {
                    "alias": "amazon-walk",
                    "src": "./assets/units/human/AmazonWalk.png"
                },
                {
                    "alias": "human-attack",
                    "src": "./assets/units/human/HumanAttack.png"
                },
                {
                    "alias": "human-idle",
                    "src": "./assets/units/human/HumanIdle.png"
                },
                {
                    "alias": "human-soul-die",
                    "src": "./assets/units/human/HumanSoulDie.png"
                },
                {
                    "alias": "human-walk",
                    "src": "./assets/units/human/HumanWalk.png"
                },
                {
                    "alias": "shadow-humanoid-attack",
                    "src": "./assets/units/human/ShadowHumanoidAttack.png"
                },
                {
                    "alias": "shadow-humanoid-idle",
                    "src": "./assets/units/human/ShadowHumanoidIdle.png"
                },
                {
                    "alias": "shadow-humanoid-soul-die",
                    "src": "./assets/units/human/ShadowHumanoidSoulDie.png"
                },
                {
                    "alias": "shadow-humanoid-spin-die",
                    "src": "./assets/units/human/ShadowHumanoidSpinDie.png"
                },
                {
                    "alias": "shadow-humanoid-walk",
                    "src": "./assets/units/human/ShadowHumanoidWalk.png"
                },
                {
                    "alias": "shadow-shot-human",
                    "src": "./assets/units/human/ShadowShotHuman.png"
                },
                {
                    "alias": "shot-bow-back",
                    "src": "./assets/units/human/ShotBowBack.png"
                },
                {
                    "alias": "shot-bow-front",
                    "src": "./assets/units/human/ShotBowFront.png"
                },
                {
                    "alias": "shot-human",
                    "src": "./assets/units/human/ShotHuman.png"
                },
                {
                    "alias": "minotaur-attack",
                    "src": "./assets/units/minotaur/MinotaurAttack.png"
                },
                {
                    "alias": "minotaur-die",
                    "src": "./assets/units/minotaur/MinotaurDie.png"
                },
                {
                    "alias": "minotaur-idle",
                    "src": "./assets/units/minotaur/MinotaurIdle.png"
                },
                {
                    "alias": "minotaur-walk",
                    "src": "./assets/units/minotaur/MinotaurWalk.png"
                },
                {
                    "alias": "shadow-minotaur-attack",
                    "src": "./assets/units/minotaur/ShadowAttack.png"
                },
                {
                    "alias": "shadow-minotaur-die",
                    "src": "./assets/units/minotaur/ShadowDie.png"
                },
                {
                    "alias": "shadow-minotaur-idle",
                    "src": "./assets/units/minotaur/ShadowIdle.png"
                },
                {
                    "alias": "shadow-minotaur-walk",
                    "src": "./assets/units/minotaur/ShadowWalk.png"
                },
                {
                    "alias": "cleric-die",
                    "src": "./assets/units/cleric/ClericDie.png"
                },
                {
                    "alias": "cleric-pray",
                    "src": "./assets/units/cleric/ClericPray.png"
                },
                {
                    "alias": "cleric-idle",
                    "src": "./assets/units/cleric/ClericIdle.png"
                },
                {
                    "alias": "cleric-walk",
                    "src": "./assets/units/cleric/ClericWalk.png"
                },
                {
                    "alias": "shadow-cleric-die",
                    "src": "./assets/units/cleric/ClericDieShadow.png"
                },
                {
                    "alias": "shadow-cleric-pray",
                    "src": "./assets/units/cleric/ClericPrayShadow.png"
                },
                {
                    "alias": "shadow-cleric-idle",
                    "src": "./assets/units/cleric/ClericIdleShadow.png"
                },
                {
                    "alias": "shadow-cleric-walk",
                    "src": "./assets/units/cleric/ClericWalkShadow.png"
                },
                {
                    "alias": "polar-bear-attack",
                    "src": "./assets/units/bear/PolarBearAttack.png"
                },
                {
                    "alias": "polar-bear-die",
                    "src": "./assets/units/bear/PolarBearDie.png"
                },
                {
                    "alias": "polar-bear-idle",
                    "src": "./assets/units/bear/PolarBearIdle.png"
                },
                {
                    "alias": "polar-bear-walk",
                    "src": "./assets/units/bear/PolarBearWalk.png"
                },
                {
                    "alias": "shadow-polar-bear-attack",
                    "src": "./assets/units/bear/PolarBearAttackShadow.png"
                },
                {
                    "alias": "shadow-polar-bear-die",
                    "src": "./assets/units/bear/PolarBearDieShadow.png"
                },
                {
                    "alias": "shadow-polar-bear-idle",
                    "src": "./assets/units/bear/PolarBearIdleShadow.png"
                },
                {
                    "alias": "shadow-polar-bear-walk",
                    "src": "./assets/units/bear/PolarBearWalkShadow.png"
                },
                {
                    "alias": "wizard-attack",
                    "src": "./assets/units/wizard/WizardFireball.png"
                },
                {
                    "alias": "wizard-die",
                    "src": "./assets/units/wizard/WizardDie.png"
                },
                {
                    "alias": "wizard-idle",
                    "src": "./assets/units/wizard/WizardIdle.png"
                },
                {
                    "alias": "wizard-walk",
                    "src": "./assets/units/wizard/WizardWalk.png"
                },
                {
                    "alias": "shadow-wizard-attack",
                    "src": "./assets/units/wizard/WizardFireballShadow.png"
                },
                {
                    "alias": "shadow-wizard-die",
                    "src": "./assets/units/wizard/WizardDieShadow.png"
                },
                {
                    "alias": "shadow-wizard-idle",
                    "src": "./assets/units/wizard/WizardIdleShadow.png"
                },
                {
                    "alias": "shadow-wizard-walk",
                    "src": "./assets/units/wizard/WizardWalkShadow.png"
                },
                {
                    "alias": "toad-attack",
                    "src": "./assets/units/toad/ToadAttackRaider_azusa.png"
                },
                {
                    "alias": "toad-die",
                    "src": "./assets/units/toad/ToadDieRaider_azusa.png"
                },
                {
                    "alias": "toad-idle",
                    "src": "./assets/units/toad/ToadIdleRaider_azusa.png"
                },
                {
                    "alias": "toad-jump",
                    "src": "./assets/units/toad/ToadJumpRaider_azusa.png"
                },
                {
                    "alias": "shadow-toad-attack",
                    "src": "./assets/units/toad/ToadAttackShadow.png"
                },
                {
                    "alias": "shadow-toad-die",
                    "src": "./assets/units/toad/ToadDieShadow.png"
                },
                {
                    "alias": "shadow-toad-idle",
                    "src": "./assets/units/toad/ToadIdleShadow.png"
                },
                {
                    "alias": "shadow-toad-jump",
                    "src": "./assets/units/toad/ToadJumpShadow.png"
                },
                {
                    "alias": "devil-attack",
                    "src": "./assets/units/devil/AngelOfDeathAttack_azusa.png"
                },
                {
                    "alias": "devil-die",
                    "src": "./assets/units/devil/AngelOfDeathDie_azusa.png"
                },
                {
                    "alias": "devil-fly",
                    "src": "./assets/units/devil/AngelOfDeathFly_azusa.png"
                },
                {
                    "alias": "devil-idle",
                    "src": "./assets/units/devil/AngelOfDeathIdle_azusa.png"
                },
                {
                    "alias": "shadow-devil-attack",
                    "src": "./assets/units/devil/AngelOfDeathAttackShadow.png"
                },
                {
                    "alias": "shadow-devil-die",
                    "src": "./assets/units/devil/AngelOfDeathDieShadow.png"
                },
                {
                    "alias": "shadow-devil-fly",
                    "src": "./assets/units/devil/AngelOfDeathFlyShadow.png"
                },
                {
                    "alias": "shadow-devil-idle",
                    "src": "./assets/units/devil/AngelOfDeathIdleShadow.png"
                },
                {
                    "alias": "cactus-attack",
                    "src": "./assets/units/cactus/CactusAttack.png"
                },
                {
                    "alias": "cactus-die",
                    "src": "./assets/units/cactus/CactusDie.png"
                },
                {
                    "alias": "cactus-idle",
                    "src": "./assets/units/cactus/CactusIdle.png"
                },
                {
                    "alias": "cactus-walk",
                    "src": "./assets/units/cactus/CactusWalk.png"
                },
                {
                    "alias": "cactus-attack-shadow",
                    "src": "./assets/units/cactus/CactusAttackShadow.png"
                },
                {
                    "alias": "cactus-die-shadow",
                    "src": "./assets/units/cactus/CactusDieShadow.png"
                },
                {
                    "alias": "cactus-idle-shadow",
                    "src": "./assets/units/cactus/CactusIdleShadow.png"
                },
                {
                    "alias": "cactus-walk-shadow",
                    "src": "./assets/units/cactus/CactusWalkShadow.png"
                },
                {
                    "alias": "book-attack",
                    "src": "./assets/units/book/BookAttack.png"
                },
                {
                    "alias": "book-attack-front",
                    "src": "./assets/units/book/BookAttackFront.png"
                },
                {
                    "alias": "book-die",
                    "src": "./assets/units/book/BookDie.png"
                },
                {
                    "alias": "book-idle",
                    "src": "./assets/units/book/BookIdle.png"
                },
                {
                    "alias": "book-attack-shadow",
                    "src": "./assets/units/book/BookAttackShadow.png"
                },
                {
                    "alias": "book-die-shadow",
                    "src": "./assets/units/book/BookDieShadow.png"
                },
                {
                    "alias": "book-idle-shadow",
                    "src": "./assets/units/book/BookIdleShadow.png"
                },
            ]
        },
        {
            "name": "spells",
            "assets": [
                { "alias": "fireball-idle", "src": "./assets/spells/Fireball.png" },
                { "alias": "fireball-tiles", "src": "./assets/spells/FireballTiles.png" },
                { "alias": "ice-idle", "src": "./assets/spells/Ice.png" },
                { "alias": "ice-tiles", "src": "./assets/spells/IceTiles.png" },
                { "alias": "faster-idle", "src": "./assets/spells/Faster.png" },
            ]
        },
        {
            "name": "projectiles",
            "assets": [
                {
                    "alias": "arrow-normal",
                    "src": "./assets/projectiles/Arrow.png"
                },
                {
                    "alias": "arrow-electric",
                    "src": "./assets/projectiles/ArrowElectric.png"
                },
                {
                    "alias": "arrow-fire",
                    "src": "./assets/projectiles/ArrowFire.png"
                },
                {
                    "alias": "arrow-ice",
                    "src": "./assets/projectiles/ArrowIce.png"
                },
                {
                    "alias": "arrow-poison",
                    "src": "./assets/projectiles/ArrowPoison.png"
                },
                {
                    "alias": "fireball-normal",
                    "src": "./assets/projectiles/Fireball.png"
                },
                {
                    "alias": "fireball-electric",
                    "src": "./assets/projectiles/FireballElectric.png"
                },
                {
                    "alias": "fireball-fire",
                    "src": "./assets/projectiles/FireballFire.png"
                },
                {
                    "alias": "fireball-ice",
                    "src": "./assets/projectiles/FireballIce.png"
                },
                {
                    "alias": "fireball-poison",
                    "src": "./assets/projectiles/FireballPoison.png"
                }
            ]
        },
        {
            "name": "gui",
            "assets": [
                { "alias": "button", "src": "./assets/gui/Button.png" },
                { "alias": "panel", "src": "./assets/gui/Panel.png" },
                { "alias": "panel-shadow", "src": "./assets/gui/PanelShadow.png" },
                { "alias": "blue-panel", "src": "./assets/gui/BluePanel.png" },
                { "alias": "yellow-panel", "src": "./assets/gui/YellowPanel.png" },
                { "alias": "purple-panel", "src": "./assets/gui/PurplePanel.png" },
                { "alias": "icons", "src": "./assets/gui/Icons.png" },
                { "alias": "custom-icons", "src": "./assets/gui/CustomIcons.png" },
                { "alias": "bars", "src": "./assets/gui/Bars.png" },
                { "alias": "azusa", "src": "./assets/gui/Azusa.png" }
            ]
        },
        {
            "name": "vfx",
            "assets": [
                { "alias": "burst-electric", "src": "./assets/vfx/BurstElectric.png" },
                { "alias": "burst-fire", "src": "./assets/vfx/BurstFire.png" },
                { "alias": "burst-ice", "src": "./assets/vfx/BurstIce.png" },
                { "alias": "burst-poison", "src": "./assets/vfx/BurstPoison.png" },
                { "alias": "heal-back", "src": "./assets/vfx/HealBack.png" },
                { "alias": "heal-front", "src": "./assets/vfx/HealFront.png" },
                { "alias": "buff-purple-back", "src": "./assets/vfx/BuffPurpleBack.png" },
                { "alias": "buff-purple-front", "src": "./assets/vfx/BuffPurpleFront.png" },
                { "alias": "shield-normal", "src": "./assets/vfx/ShieldNormal1.png" },
                { "alias": "shield-electric", "src": "./assets/vfx/ShieldElectric1.png" },
                { "alias": "shield-fire", "src": "./assets/vfx/ShieldFire1.png" },
                { "alias": "shield-ice", "src": "./assets/vfx/ShieldIce1.png" },
                { "alias": "shield-poison", "src": "./assets/vfx/ShieldPoison1.png" },
                { "alias": "status-frozen", "src": "./assets/vfx/StatusFrozen.png" },
                { "alias": "status-poison", "src": "./assets/vfx/StatusPoison.png" },
                { "alias": "status-shock", "src": "./assets/vfx/StatusShock.png" },
            ]
        },
        {
            "name": "fonts",
            "assets": [
                { "alias": "fusion_pixel", "src": "./assets/fonts/fusion_pixel.woff2" }
            ]
        },
        {
            "name": "logo",
            "assets": [
                { "alias": "logo", "src": "./assets/logo/logo.png" }
            ]
        },
        {
            "name": "loading",
            "assets": [
                { "alias": "loading", "src": "./assets/loading/Loading.png" }
            ]
        },
        {
            "name": "credits",
            "assets": [
                { "alias": "credits", "src": "./assets/credits/Credits.png" }
            ]
        }
    ]
}

class AssetLoader {
    constructor() {
        Assets.init({ manifest });

        this.frames = {};
    }

    loadInBackground() {
        Assets.backgroundLoadBundle(["units", "projectiles", "vfx", "spells", "loading"]);
    }

    async loadFonts() {
        await Assets.loadBundle(["fonts"]);
    }

    async load(onProgress) {
        await Assets.loadBundle(["fonts", "logo", "terrain", "gui"], onProgress);
    }

    async getFrames(key, options = { scaleMode: 'nearest', frameSize: 32 }) {
        if (this.frames[key]) {
            return this.frames[key];
        }

        const texture = await Assets.load(key);
        texture.source.scaleMode = options.scaleMode || 'nearest';

        const frames = [];
        const frameWidth = options.frameSize || 32;
        const frameHeight = options.frameSize || 32;
        
        const totalX = Math.floor(texture.width / frameWidth);
        const totalY = Math.floor(texture.height / frameHeight);

        for (let y = 0; y < totalY; y++) {
            const row = [];
            for (let x = 0; x < totalX; x++) {
                const frame = new Texture({
                    source: texture,
                    frame: new Rectangle(x * frameWidth, y * frameHeight, frameWidth, frameHeight)
                });
                row.push(frame);
            }
            frames.push(row);
        }

        this.frames[key] = frames;
        return frames;
    }
}

const assetLoader = new AssetLoader();

export default assetLoader;
