import UnitCard from './UnitCard';

export const HUMAN = new UnitCard({
    type: 'human',
    alias: '士兵',
    atkType: 'melee',
    level: 0,
    cost: 1,
    hp: 3,
    atk: 1,
    def: 0,
    spd: 0,
    atkSpd: 0,
    atkRange: 0,
    skills: [[null], [], []],
    spriteKey: 'amazon',
});

export const ARCHER = new UnitCard({
    type: 'archer',
    alias: '弓箭手',
    atkType: 'arrow',
    level: 0,
    cost: 2,
    hp: 1,
    atk: 1,
    def: 0,
    spd: 0,
    atkSpd: 0,
    atkRange: 6,
    skills: [[null], [], []],
    spriteKey: 'human',
});

export const MINOTAUR = new UnitCard({
    type: 'minotaur',
    alias: '牛头人',
    atkType: 'melee-area',
    level: 0,
    cost: 3,
    hp: 5,
    atk: 2,
    def: 1,
    spd: -1,
    atkSpd: 0,
    atkRange: 2,
    skills: [[null], [], []],
    spriteKey: 'minotaur',
});

export const CLERIC = new UnitCard({
    type: 'cleric',
    alias: '牧师',
    atkType: 'heal',
    level: 0,
    cost: 4,
    hp: 2,
    atk: 2,
    def: 0,
    spd: -1,
    atkSpd: 0,
    atkRange: 4,
    skills: [[null], [], []],
    spriteKey: 'cleric',
});

export const POLAR_BEAR = new UnitCard({
    type: 'polar-bear',
    alias: '熊',
    atkType: 'melee',
    level: 0,
    cost: 5,
    hp: 8,
    atk: 5,
    def: 0,
    spd: 2,
    atkSpd: 0,
    atkRange: 0,
    skills: [[null], [], []],
    spriteKey: 'polar-bear',
});

export const WIZARD = new UnitCard({
    type: 'wizard',
    alias: '巫师',
    atkType: 'magic',
    level: 0,
    cost: 6,
    hp: 3,
    atk: 3,
    def: 0,
    spd: 0,
    atkSpd: 0,
    atkRange: 4,
    aoeRadius: 20,
    skills: [[null], [], []],
    spriteKey: 'wizard',
});

export const TOAD = new UnitCard({
    type: 'toad',
    alias: '阿梓宝',
    atkType: 'melee',
    level: 0,
    cost: 7,
    hp: 10,
    atk: 1,
    def: 2,
    spd: -1,
    atkSpd: 0,
    atkRange: 2,
    skills: [[null], [], []],
    spriteKey: 'toad',
});

export const DEVIL = new UnitCard({
    type: 'devil',
    alias: '梓魔',
    atkType: 'melee-area',
    level: 0,
    cost: 8,
    hp: 8,
    atk: 4,
    def: 1,
    spd: 2,
    atkSpd: 0,
    atkRange: 1,
    skills: [[null], [], []],
    spriteKey: 'devil',
});

export const CACTUS = new UnitCard({
    type: 'cactus',
    alias: '仙人掌',
    atkType: 'melee-area',
    level: 0,
    cost: 0,
    hp: 1,
    atk: 1,
    def: 0,
    spd: -1,
    atkSpd: 0,
    atkRange: 0,
    skills: [[null], [], []],
    spriteKey: 'cactus',
});

export const BOOK = new UnitCard({
    type: 'book',
    alias: '书',
    atkType: 'melee-area',
    level: 0,
    cost: 0,
    hp: 1,
    atk: 1,
    def: 0,
    spd: 1,
    atkSpd: 0,
    atkRange: 0,
    skills: [[null], [], []],
    spriteKey: 'book',
});
