export const createLoginForm = (callback) => {
    let resolveCallback;
    let rejectCallback;

    const loginForm = document.createElement('form');
    loginForm.style.position = 'absolute';
    loginForm.style.top = '50%';
    loginForm.style.left = '50%';
    loginForm.style.maxWidth = '80%';
    loginForm.style.transform = 'translate(-50%, -50%)';
    loginForm.style.backgroundColor = 'white';
    loginForm.style.padding = '20px';
    loginForm.style.borderRadius = '10px';
    loginForm.style.display = 'flex';
    loginForm.style.flexDirection = 'column';
    loginForm.style.gap = '10px';
    const logo = document.createElement('img');
    logo.src = './assets/logo/logo.png';
    logo.style.width = '256px';
    logo.style.height = '128px';
    logo.style.alignSelf = 'center';
    loginForm.appendChild(logo);
    const usernameInput = document.createElement('input');
    usernameInput.type = 'text';
    usernameInput.style.padding = '10px';
    usernameInput.style.borderRadius = '5px';
    usernameInput.style.border = '1px solid #ccc';
    usernameInput.style.outline = 'none';
    usernameInput.style.fontFamily = 'Fusion Pixel';
    usernameInput.style.fontSize = '24px';
    usernameInput.placeholder = '用户名';
    loginForm.appendChild(usernameInput);
    const passwordInput = document.createElement('input');
    passwordInput.type = 'password';
    passwordInput.style.padding = '10px';
    passwordInput.style.borderRadius = '5px';
    passwordInput.style.border = '1px solid #ccc';
    passwordInput.style.outline = 'none';
    passwordInput.style.fontFamily = 'Fusion Pixel';
    passwordInput.style.fontSize = '24px';
    passwordInput.placeholder = '密码';
    loginForm.appendChild(passwordInput);
    const loginButton = document.createElement('button');
    loginButton.innerText = '登录';
    loginButton.type = 'submit';
    loginButton.style.padding = '10px';
    loginButton.style.borderRadius = '5px';
    loginButton.style.border = '1px solid #ccc';
    loginButton.style.outline = 'none';
    loginButton.style.fontFamily = 'Fusion Pixel';
    loginButton.style.fontSize = '24px';
    loginButton.style.cursor = 'pointer';
    loginForm.appendChild(loginButton);
    const signupButton = document.createElement('button');
    signupButton.innerText = '注册';
    signupButton.type = 'button';
    signupButton.style.padding = '10px';
    signupButton.style.borderRadius = '5px';
    signupButton.style.border = '1px solid #ccc';
    signupButton.style.outline = 'none';
    signupButton.style.fontFamily = 'Fusion Pixel';
    signupButton.style.fontSize = '24px';
    signupButton.style.cursor = 'pointer';
    loginForm.appendChild(signupButton);
    const errorMessage = document.createElement('div');
    errorMessage.style.color = 'auto';
    errorMessage.style.fontFamily = 'Fusion Pixel';
    errorMessage.style.fontSize = '24px';
    errorMessage.innerHTML = '信息安全提示：对于非官方App，请一定<b>不要使用常用密码</b>';
    loginForm.appendChild(errorMessage);

    loginForm.addEventListener('submit', async (event) => {
        event.preventDefault();
        const username = usernameInput.value;
        const password = passwordInput.value;
        const hashedPassword = await hashPassword(username, password);

        errorMessage.innerText = '';
        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password: hashedPassword }),
            });
            if (response.ok) {
                resolveCallback();
            } else {
                const { message } = await response.json();
                errorMessage.style.color = 'red';
                errorMessage.innerText = `登录失败: ${message}`;
            }
        } catch (error) {
            errorMessage.style.color = 'red';
            errorMessage.innerText = `登录失败: ${error.message}`;
        }
    });
    signupButton.addEventListener('click', async (event) => {
        event.preventDefault();
        const username = usernameInput.value;
        const password = passwordInput.value;
        const hashedPassword = await hashPassword(username, password);
        
        errorMessage.innerText = '';
        try {
            const response = await fetch('/api/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password: hashedPassword }),
            });
            if (response.ok) {
                errorMessage.style.color = 'green';
                errorMessage.innerText = `注册成功，请登录...`;
            } else {
                const { message } = await response.json();
                errorMessage.style.color = 'red';
                errorMessage.innerText = `注册失败: ${message}`;
            }
        } catch (error) {
            errorMessage.style.color = 'red';
            errorMessage.innerText = `注册失败: ${error.message}`;
        }
    });

    return {
        form: loginForm,
        loginPromise: new Promise((resolve, reject) => {
            resolveCallback = resolve;
            rejectCallback = reject;
        }),
    };
}

const hashPassword = async (username, password) => {
    const salt = `${username}-mixergame-deadbeef`;
    
    const encoder = new TextEncoder();
    const passwordBuffer = encoder.encode(password);
    const saltBuffer = encoder.encode(salt);

    const keyMaterial = await crypto.subtle.importKey(
        'raw',
        passwordBuffer,
        'PBKDF2',
        false,
        ['deriveBits']
    );
    
    const hash = await crypto.subtle.deriveBits(
        {
            name: 'PBKDF2',
            salt: saltBuffer,
            iterations: 1000,
            hash: 'SHA-512'
        },
        keyMaterial,
        512
    );
    
    return Array.from(new Uint8Array(hash))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
}
