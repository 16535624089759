export default class UnitCard {
    constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.alias = data.alias;

        this.atkType = data.atkType;
        this.level = data.level;
        this.cost = data.cost;
        this.hp = data.hp;
        this.atk = data.atk;
        this.def = data.def;
        this.spd = data.spd;
        this.atkSpd = data.atkSpd;
        this.atkRange = data.atkRange;
        this.aoeRadius = data.aoeRadius;

        this.skills = [];
        for (let i = 0; i < data.skills.length; i++) {
            this.skills.push([...data.skills[i]]);
        }

        this.spriteKey = data.spriteKey;
        this.ownerId = data.ownerId;
        this.ownerName = data.ownerName;
    }

    clone() {
        return new UnitCard(this);
    }

    compare(other) {
        const typeIndex = this.getTypeIndex();
        const otherTypeIndex = other.getTypeIndex();
        if (typeIndex !== otherTypeIndex) {
            return typeIndex - otherTypeIndex;
        }
        if (this.level !== other.level) {
            return other.level - this.level;
        }
        return this.id.localeCompare(other.id);
    }

    getTypeIndex() {
        return ['human', 'archer', 'minotaur', 'cleric', 'polar-bear', 'wizard', 'toad', 'devil'].indexOf(this.type);
    }

    getSkillTotalLevel(skill) {
        let total = 0;
        for (let i = 0; i < this.skills.length; i++) {
            if (this.skills[i].includes(skill)) {
                total += i + 1;
            }
        }
        return total;
    }

    isSpell() {
        return this.type.startsWith('spell-');
    }
}
