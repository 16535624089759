export const allSkills = {
    1: [
        {
            type: 'hp',
            name: '生命',
            description: '生命值+1',
        },
        {
            type: 'atk',
            name: '攻击',
            description: '攻击力+1',
        },
        {
            type: 'def',
            name: '防御',
            description: '防御力+1',
        },
        {
            type: 'spd',
            name: '移动速度',
            description: '移动速度+1',
        },
        {
            type: 'atk-spd',
            name: '攻击速度',
            description: '攻击速度+1',
        },
        {
            type: 'atk-range',
            name: '攻击距离',
            description: '攻击距离+1',
        },
        {
            type: 'cost',
            name: '费用减少',
            description: '费用减少1',
        },
    ],
    2: [
        
        {
            type: 'hp',
            name: '生命',
            description: '生命值+2',
        },
        {
            type: 'atk',
            name: '攻击',
            description: '攻击力+2',
        },
        {
            type: 'def',
            name: '防御',
            description: '防御力+2',
        },
        {
            type: 'spd',
            name: '移动速度',
            description: '移动速度+2',
        },
        {
            type: 'atk-spd',
            name: '攻击速度',
            description: '攻击速度+2',
        },
        {
            type: 'atk-range',
            name: '攻击距离',
            description: '攻击距离+2',
        },
        {
            type: 'cost',
            name: '费用减少',
            description: '费用减少2',
        },
        {
            type: 'electric',
            name: '闪电',
            description: '造成闪电伤害',
        },
        {
            type: 'fire',
            name: '火焰',
            description: '造成火焰伤害',
        },
        {
            type: 'ice',
            name: '冰冻',
            description: '造成冰冻伤害',
        },
        {
            type: 'poison',
            name: '猛毒',
            description: '造成猛毒伤害',
        },
    ],
    3: [
        {
            type: 'number',
            name: '小孩梓集合',
            description: '召唤数量',
        },
        {
            type: 'self-food',
            name: '情感循环',
            description: '自顾自感动',
        },
        {
            type: 'self-heal',
            name: '幸福歌唱',
            description: '自动恢复生命',
        },
        {
            type: 'companion',
            name: '扎人们',
            description: '召唤同伴',
        },
        {
            type: 'stronger',
            name: '人之初',
            description: '属性全面增加',
        },
        {
            type: 'aoe',
            name: '捏麻麻滴',
            description: '攻击范围敌人',
        },
        {
            type: 'shield',
            name: '红豆泥',
            description: '生成护盾',
        },
        {
            type: 'vampire',
            name: '摄人心魂',
            description: '击杀吸血',
        },
    ],
};

export const skillFocus = {
    'human': ['hp', 'number'],
    'archer': ['atk-range', 'self-food'],
    'minotaur': ['def', 'self-heal'],
    'cleric': ['atk', 'companion'],
    'polar-bear': ['spd', 'stronger'],
    'wizard': ['electric', 'fire', 'ice', 'poison', 'aoe'],
    'toad': ['cost', 'shield'],
    'devil': ['atk-spd', 'vampire'],
};

export const allSkillNames = Object.values(allSkills)
    .flatMap(skills => skills)
    .reduce((map, skill) => {
        map[skill.type] = skill.name;
        return map;
    }, {});

export const skillColor = [0x000000, 0x6c8eb8, 0xe0b700];
